import React, {useState, useEffect} from 'react'
// style
import './modal.css'

const Modal = ({isOpen, onPress}) => {
  const [open, setOpen] = useState(isOpen)

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  return (
    <div>
      <div className={open ? "modal block" : "modal none"}>
        <div className="modal-sub-container">
          <div className="modal-content">
            <div className="modal-header">
              <h2>Bravo !</h2>
              <button onClick={() => onPress()} className="close">&times;</button>
            </div>
            <div className="modal-body">
              <h3>Demande envoyée</h3>
              <br/>
              <p>Votre message a bien été envoyé !</p>
              <p>Vous allez recevoir un mail de confirmation ;)</p>
              <br/>
              <p>À bientôt.</p>
            </div>
            <div className="modal-footer">
              <button onClick={() => onPress()}>Fermer</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
