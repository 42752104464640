import React, {useState} from "react"
import {Helmet} from "react-helmet";
import Img from "gatsby-image";
import {graphql} from "gatsby";
import { Formik } from 'formik';
import * as Yup from 'yup';
// components
import HeaderQuery from "../components/header/header";
import BasicBlock from "../components/basicBlock/basicBlock";
import Modal from "../components/modal/modal";
import Footer from "../components/footer/footer";
// styles
import '../styles/contact.css'

const ContactSchema = Yup.object().shape({
  prenom: Yup.string().required('Le prénom est obligatoire !'),
  nom: Yup.string().required('Le nom est obligatoire !'),
  email: Yup.string().email("l'émail est invalide !").required("L'émail est obligatoire"),
  body: Yup.string()
    .min(20, '20 caractères minimums')
    .required('Le message est obligatoire !'),
});

const Contact = ({data, path}) => {
  // variables
  const [modalIsOpen, setModalIsOpen] = useState(false)
  // functions
  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
  const onSubmit = async (e, a) => {
    await sleep(500);
    const response = await fetch('https://webdevvision.fr:5999/contact', {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sender: e.email,
        text: `prenom: ${e.prenom}, nom: ${e.nom}, email: ${e.email}, tel: ${e.tel}, text: ${e.body}`,
      }),
    });
    if (response.status !== 500) {
      setModalIsOpen(true)
      a.resetForm({
        values: {
          prenom: '',
          nom: '',
          email: '',
          tel: '',
          body: '',
        },
      });
    }
  }
  // render
  return (
    <main>
      <Modal isOpen={modalIsOpen} onPress={() => setModalIsOpen(false)} />
      <Helmet>
        <meta charSet="utf-8" />
        <html lang="fr" />
        <title>Me contacter</title>
        <link rel="canonical" href="https://webdevvision.fr/contact/" />
        <meta
          name="description"
          content="Nous sommes une agence de développement Web et Mobile sur Gaillac, passionnée et engagée dans la stratégie digitale, la conception UI / UX et le développement d’applications web et mobile."
        />
      </Helmet>
      <HeaderQuery />
      <BasicBlock
        containerLeft={() => (
          <>
            <h1 className="contact-header primary">
              Nous contacter
              <br/>
            </h1>
            <p className="secondary contact-subTitle">
              Vous souhaitez nous contacter pour de futurs projets ? Besoin de conseils ?
              <br/>
              Nous sommes là pour vous alors n'hésitez pas !
            </p>
          </>
        )}
        containerRight={() => (
          <>
            <Img fluid={data.file.childImageSharp.fluid}  />
          </>
        )}
        containerRightFirstOnMobile
        containerRightContainPicture
        containerLeftContainPicture={false}
        background="tertiary"
      />
      <div className="contact-container-formulaire" id="form">
        <div className="contact-formulaire">
          <Formik
            initialValues={{
              prenom: '',
              nom: '',
              email: '',
              tel: '',
              body: '',
            }}
            onSubmit={onSubmit}
            validationSchema={ContactSchema}
          >
            {
              ({
                values, errors, touched, handleChange,
                handleBlur, handleSubmit, isSubmitting,
              }) => (
              <form className="contact-sub-formulaire" onSubmit={handleSubmit}>
                <div className="contact-container-form-1">
                  <input
                    name="prenom"
                    type="text"
                    placeholder="Prénom*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.prenom}
                  />
                  {(touched.prenom && errors.prenom) &&
                  <div>
                    <p className="contact-error-text">
                      {errors.prenom}
                    </p>
                  </div>
                  }
                </div>
                <div className="contact-container-form-2">
                  <input
                    name="nom"
                    type="text"
                    placeholder="Nom*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.nom}
                  />
                  {(touched.nom && errors.nom) &&
                  <div>
                    <p className="contact-error-text">
                      {errors.nom}
                    </p>
                  </div>
                  }
                </div>
                <div className="contact-container-form-3">
                  <input
                    name="email"
                    type="email"
                    placeholder="Email*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  {(touched.email && errors.email) &&
                  <div>
                    <p className="contact-error-text">
                      {errors.email}
                    </p>
                  </div>
                  }
                </div>
                <div className="contact-container-form-4">
                  <input
                    name="tel"
                    type="tel"
                    placeholder="Numéro de téléphone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.tel}
                  />
                </div>
                <div className="contact-container-form-5">
                  <textarea
                    name="body"
                    rows="10"
                    placeholder="Votre message*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.body}
                  />
                  {(touched.body && errors.body) &&
                  <div>
                    <p className="contact-error-text">
                      {errors.body}
                    </p>
                  </div>
                  }
                </div>
                <div className="contact-container-form-6">
                  <button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? (
                      <div className="lds-dual-ring"/>
                    ) : 'Envoyer'}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      <Footer path={path} />
    </main>
  )
}

export const query = graphql`
  query {
    file(relativePath: { eq: "contact.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Contact
